<template>
  <div class="main">
    <div>
      <Panel module="Painel de Reserva" layout="large" :showFilter="false" :showSearch="false" :showButtons="false">
        <div slot="content-main">
          <br />
          <ReservationPanel />
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import ReservationPanel from '../../../components/modules/operational/ReservationPanel.vue'



export default {
  name: "PanelPivotView",
  components: {
    Panel, ReservationPanel
  },
};
</script>

<style scoped>
.main {
  margin-left: 120px;
  margin-top: 100px;
  margin: auto;
}
</style>