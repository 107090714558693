<template>
    <div>
        <Molded>
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <DateTime title="Período" fieldTarget="period" format="DD/MM/YYYY" type="date" :range="true"
                        :confirm="true" confirmText="Filtrar" placeholder :required="true" v-model="selectedPeriod" />
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                    <div class="side-by-side">
                        <RadioGroup title="Status" fieldTarget="status" :options="[
                            { text: 'Reservado', value: 2 },
                            { text: 'Locado', value: 3 },
                            { text: 'Todos', value: 0 },
                        ]" v-model="status" />
                    </div>
                    <div class="side-by-side">
                        <Button _key="btnGetAll" :disabled="disabled" title="Buscar" type="info"
                            classIcon="title fas fa-search" size="medium" :clicked="getAll" />
                    </div>
                </b-col>
            </b-row>
        </Molded>
        <br>
        <div v-if="data.length == 0 && dirty">
            <span class="title">Nenhum registro encontrado!</span>
        </div>
        <vue-pivottable-ui aggregatorName="Sum" :renderers="renderers" :renderer-name="rendererName" :data="data"
            :rows="['produto']" :cols="['ano', 'mes', 'data']" :vals="['total']" :disabledFromDragDrop="['ano', 'mes']"
            :hiddenFromDragDrop="['total', '_Data']" :showAggregators="false" :locales="locales" :locale="locale">
            <template v-slot:colGroup>
                <colGroup :width="170"></colGroup>
                <colGroup></colGroup>
            </template>
            <template v-slot:output v-if="loading">
                <Loading type="line" :center="false" v-show="loading" />
            </template>
            <template v-slot:pvtAttr="{ name }">
                <i class="fas fa-filter"></i>
                {{ name[0].toUpperCase() + name.substring(1) }}
            </template>
        </vue-pivottable-ui>
    </div>
</template>
<script>

import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { VuePivottableUi, PivotUtilities } from 'vue-pivottable'
import ScrollRenderer from '@vue-pivottable/scroll-renderer'
import 'vue-pivottable/dist/vue-pivottable.css'


import { mapActions, mapMutations } from "vuex";

export default {
    name: "ReservationPanel",
    components: {
        VuePivottableUi, DateTime, Select, Loading, RadioGroup, Button, Molded
    },
    data() {
        return {
            data: [],
            locale: "pt",
            locales: {
                pt: {
                    aggregators: PivotUtilities.aggregators,
                    localeStrings: {
                        totals: "Total",
                        selectAll: "Selecionar Todos",
                        selectNone: "Nenhum",
                        renderError: 'Ocorreu um erro ao renderizar os resultados da tabela dinâmica.',
                        computeError: 'Ocorreu um erro ao renderizar os resultados da tabela dinâmica.',
                        uiRenderError: 'Ocorreu um erro ao renderizar os resultados da tabela dinâmica.',
                    },
                },
            },
            urlGet: "/api/v1/operational/get-all-reservation-panel",
            rendererName: 'Normal',
            loading: false,
            selectedPeriod: "",
            status: 0,
            disabled: true,
            dirty: false,

        }
    },
    mounted() {
        this.removeLoading(["panel"]);
    },
    computed: {
        renderers() {
            return (() => ({
                Normal: ScrollRenderer.Table,
            }))()
        },
    },
    methods: {
        ...mapMutations("generic", ["removeLoading"]),
        ...mapActions("generic", ["getApi"]),
        getAll() {
            this.loading = true;
            this.dirty = false;
            this.data = [];
            let params = {
                url: this.urlGet,
                obj: {
                    start: this.selectedPeriod[0],
                    end: this.selectedPeriod[1],
                    status: this.status,
                },
            };
            this.getApi(params).then((response) => {
                if (response.success) {
                    this.data = response.content;
                    let self = this;
                    setTimeout(function () {
                        self.dirty = true;
                        self.loading = false;
                        self.removeLoading(["panel"]);
                        self.removeLoading(["btnGetAll"]);
                        self.disabled = true;
                    }, 500);
                }
            });
        },
        cleanProduct() {
            this.product = {};
        },
    },
    watch: {
        selectedPeriod: {
            handler(selectedPeriod) {
                if (selectedPeriod[0] != "") this.disabled = false;
            },
            deep: true,
        },
        status: {
            handler() {
                this.disabled = false;
            },
            deep: true,
        },
    },
}

</script>
<style>
table {
    font-family: 'Poppins', sans-serif !important;
}

.pvtUi td.pvtOutput {
    padding-top: 40px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 40px !important;
}

.pvtUi {
    color: #424344;
}

.pvtVals {
    visibility: hidden;
}


table.pvtTable thead tr th,
table.pvtTable tbody tr th {
    min-width: 160px;
    background-color: #FAFAFC !important;
    border: 1px solid #DEE2E6;
    font-size: 13px !important;
    padding: 5px !important;
    border-radius: 0px !important;
}

.pvtAxisContainer li span.pvtAttr {
    -webkit-text-size-adjust: 100%;
    background: #EEEEEE !important;
    border: 0px solid #c8d4e3 !important;
    ;
    border-radius: 20px !important;
    ;
}

.pvtAxisContainer,
.pvtVals {
    border: 1px solid #E8EAED !important;
    background: #fff;
    padding: 5px;
    min-width: 20px;
    min-height: 20px;
}

.pvVal {
    font-size: 13px !important;
    margin: auto !important;
    font-family: 'Poppins', sans-serif !important;
}

.pvtTotal {
    font-size: 13px !important;
    margin: auto !important;
}

.pvtGrandTotal {
    font-size: 13px !important;
}

.pvtRenderers {
    border: 1px solid #E8EAED !important;
}

.pvtOutput {
    border: 1px solid #E8EAED !important;
}
</style>